const _nav = [
    {
        icon: "fa-solid fa-home",
        title: "Home",
        to: "/",

    },
    {
        icon: "fa-solid fa-user",
        title: "Profile",
        to: "/account/profile",

    },
    {
        icon: "fa-solid fa-star",
        title: "Ratings",
        to: "/account/ratings"
    },
    {
        icon: "fa-solid fa-user-tie",
        title: "Adjusters",
        to: "/account/adjusters"
    },
]

export default _nav;