import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import generateValidation from '../../../_helpers/generateValidation';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import fillInObject from '../../../_helpers/fillInObject';
import './style.css'
import FieldsGenerator from '../../../components/FieldsGenerator';
import { Alert } from '@mui/material';
import { authenticationActions } from '../../../_actions/authentication.actions';
import SocialButtons from '../../../components/SocialButtons';

const Login = ({ infosetting, dispatch }) => {
    const navigate = useNavigate();
    const bluePrint = {
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        },
        'password': {
            type: String,
            label: "Password",
            placeholder: "Enter Password",
            icon: "ti-lock",
            iconColor: "color-primary",
            dataType: "PasswordHash",
            default: "",
            required: true,
        },
    };
    const [formObj, setFormObj] = useState(null);
    const [formError, setFormError] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [checkTerm, setCheckTerm] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const redirect = searchParams.get("continue");

    useEffect(() => {
        if (infosetting) {
            document.title = `Login | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);


    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];

        dispatch(authenticationActions.login(formObj, navigate, setLoading, handleCallbackError, redirect));
    }
    const handleCallbackError = (error) => {
        console.log({ error });
        toast.error(error);

    }
    return (
        <div className='height100 pd-top'>
            <div className='column center'>
                <h2>
                    Login
                </h2>
                <form onSubmit={handleSubmit} className='form-container column center'>
                    {formObj &&
                        <FieldsGenerator
                            errors={errors}
                            formObj={formObj}
                            handleChageCustom={handleChageCustom}
                            handleChange={handleChange}
                            loggingIn={false}
                            isLoading={isLoading}
                            bluePrint={bluePrint}
                            key={0}
                        />
                    }

                    {alert.message && <Alert severity={alert.type} className="mt-3">{alert.message} </Alert>}

                    <Link className='secondary-text mt-20' to="/auth/forgot-password">Forgot Password?</Link>
                    <button type="submit" class="btn btn-block btn-sm mt-20" disabled={isLoading}>
                        {isLoading ? "Please Wait..." : "Continue"}
                    </button>
                    {/* <SocialButtons dispatch={dispatch} navigate={navigate} setLoading={setLoading} /> */}
                    <div className='row center mt-10'>
                        <p className='form-agreement'>{infosetting.title} is designed for and targeted to U.S. audiences and is governed by and operated in accordance with U.S. laws.</p>
                    </div>

                    <p className='form-sub-heading'>Already have an account? <Link to="/auth/signup" className='secondary-text'>Signup</Link></p>

                </form>
            </div>

        </div>
    );
};



function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Login);