import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import getImageURL from '../../_helpers/getImageURL';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { authenticationActions } from '../../_actions/authentication.actions';

const Header = ({ infosetting, authentication, handleLogout }) => {
    const [accountDropDown, setAccountDropDown] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const redirect = searchParams.get("redirect");

    return (
        <div className='header-nav'>

            <Link to="/">
                <img src={getImageURL(infosetting.logo)} className='header-logo' />
            </Link>

            <div className='header-button-container center'>
                {authentication.loggedIn ?
                    <Fragment>
                        <div className='btn header-signup center' onClick={() => setAccountDropDown(!accountDropDown)}>
                            My Account
                        </div>
                        {accountDropDown && <div className='nav-dropdown' onClick={() => setAccountDropDown(false)}>
                            <Link to="/account/profile">Profile</Link>
                            <Link to="/account/ratings">Ratings</Link>
                            <Link to="/account/adjusters">Adjusters</Link>
                            <span onClick={handleLogout}>Logout</span>
                        </div>}
                    </Fragment> :
                    <Fragment>
                        <Link className='header-login' to={`/auth/login${redirect ? '?redirect=' + redirect : ''}`}>
                            Login
                        </Link>
                        <Link className='btn header-signup center' to="/auth/signup">
                            Sign Up
                        </Link>
                    </Fragment>}

            </div>
        </div>
    );
};

export default Header;