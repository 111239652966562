const getColor = (rating) => {
    let color = '#FF9C9C';
    if (rating === 0) {
        color = '#A6A6A6';
    } else if (rating >= 4) {
        color = '#7FF6C3';
    } else if (rating >= 3) {
        color = '#FFF070'
    }
    return color;
}

export default getColor;