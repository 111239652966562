import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import getImageURL from '../../../_helpers/getImageURL';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import JoinRMPFamily from '../../../components/JoinRMPFamily';
import { apiService } from '../../../_services/api.service';
import getColor from '../../../_helpers/getColor';

const Home = ({ infosetting }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [ratings, setRatings] = useState([]);
    const [searchMode, setSearchMode] = useState(1);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (infosetting) {
            document.title = `${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        fetchRecentReviews();
        fetchStates();
    }, []);


    useEffect(() => {
        setCompanies([]);
        setCompany(null);
        if (selectedState) {
            apiService.filter('companies', { query: { state: selectedState } })
                .then(setCompanies)
        }
    }, [selectedState]);

    useEffect(() => {
        setCompany(null);
        setSelectedState(null);
    }, [searchMode]);


    const fetchStates = () => {
        apiService.getAll('states')
            .then(setStates)
    }

    const fetchRecentReviews = () => {
        // apiService.filter("adjuster-ratings", { sortQuery: { dateTime: - 1 }, limit: 20 })
        //     .then(setRatings);

        apiService.getAll('adjuster-ratings/recent')
            .then(setRatings);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedState && company) {
            return navigate(`/search?state=${selectedState}&company=${company}`)
        } else if (searchMode === 2) {
            return toast.error("Please select state and company!");
        }

        if (searchQuery === "") {
            return toast.error("Please enter adjuster name!");
        }
        navigate(`/search?q=${encodeURI(searchQuery)}`)
    }

    return (
        <div className='section'>
            <div className='home-banner column center' style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(${getImageURL(infosetting.homeBannerImage)})` }}>
                <span className='home-banner-title'>Discover Top-Rated Adjusters</span>
                <span className='home-banner-caption'>Your Ultimate Property Adjuster Search Engine</span>

                <form onSubmit={handleSubmit} className='column center'>
                    {searchMode === 1 ? <div className='column center'>
                        <div className='hsb-container'>
                            <i class="fa-solid fa-user-tag"></i>
                            <input name='search' placeholder='Adjuster or Company Name' value={searchQuery} onChange={({ target }) => setSearchQuery(target.value)} />
                        </div>
                        <span className='home-banner-mode' onClick={() => setSearchMode(2)}>I want to find adjuster with state and company</span>
                    </div>
                        :
                        <div className='column center'>
                            <div className='hsb-container'>
                                <i class="fa-solid fa-globe"></i>
                                <select name='state' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                    <option>Select State</option>
                                    {states.map((s, i) => <option value={s._id}>{s.name}</option>)}
                                </select>
                            </div>

                            <div className='hsb-container mt-20'>
                                <i class="fa-solid fa-building"></i>
                                <select name='state' value={company} onChange={(e) => setCompany(e.target.value)}>
                                    <option>Select Company</option>
                                    {companies.map((s, i) => <option key={i} value={s._id}>{s.name}</option>)}

                                </select>
                            </div>
                            <button className='btn btn-primary mt-20 btn-block'>
                                Search
                            </button>
                            <span className='home-banner-mode' onClick={() => setSearchMode(1)}>I want to find adjuster with name</span>

                        </div>
                    }
                </form>

            </div >
            <JoinRMPFamily infosetting={infosetting} />
            
            {
                ratings.length > 0 && <div className='hrr-container column center padder'>
                    <span className='home-banner-title'>Recent Rating & Reviews</span>
                    <div className='row hrr-content'>
                        {ratings.map((rating, i) => <div className='adjuster-item row p-rating-item' key={i} onClick={() => navigate(`/adjuster/${rating.adjuster}`)}>
                            <div className='a-rating-container column'>
                                <span className='a-rating-heading'>Rating</span>
                                <div className='a-rating-box row center' style={{ backgroundColor: getColor(rating.rating) }}>
                                    <span className='a-rating'>
                                        {rating.rating}{rating.rating.toString().length === 1 ? '.0' : ''}
                                    </span>
                                </div>
                            </div>

                            <div className='a-name-container column p-rating-item-name'>
                                <span className='a-name'>{rating.adjusterObj.first_name} {rating.adjusterObj.last_name} - {rating.adjusterObj.companyObj.name}</span>
                                <span className='ap-review-user-text'>{rating.userObj.name}</span>
                                <span className='ap-review-text'>{rating.review}</span>
                            </div>
                            <div className='a-state-container a-state-container-adjuster column'>
                                <span className='a-state-name'>{new Date(rating.dateTime).toLocaleDateString()}</span>
                            </div>
                        </div>)}
                    </div>
                </div>
            }
        </div >
    );
};


function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Home);