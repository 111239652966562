import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { apiService } from '../../../_services/api.service';
import { APILoader } from '../../../components/APILoader';
import getColor from '../../../_helpers/getColor';

const Ratings = ({ infosetting, user }) => {
    const [ratingList, setRatingList] = useState(null);
    useEffect(() => {
        if (infosetting) {
            document.title = `Ratings | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        if (user) {
            apiService.filter("adjuster-ratings", { query: { user: user._id } })
                .then(setRatingList)
        }
    }, [user]);

    return (
        <div>
            <h3>Ratings</h3>
            {ratingList ?
                ratingList.length > 0 ?
                    <div className='height60'>
                        {ratingList.map((rating, key) => <div className='adjuster-item row p-rating-item' key={key}>
                            <div className='a-rating-container column '>
                                <span className='a-rating-heading'>Rating</span>
                                <div className='a-rating-box row center' style={{ backgroundColor: getColor(rating.rating) }}>
                                    <span className='a-rating'>
                                        {rating.rating}{rating.rating.toString().length === 1 ? '.0' : ''}
                                    </span>
                                </div>
                            </div>
                            <div className='a-name-container column p-rating-item-name'>
                                <span className='a-name'>{rating.adjusterObj.last_name}</span>
                                <span className='ap-review-text'>{rating.review}</span>
                            </div>
                            <div className='a-state-container row'>
                                <span className='a-state-name'>{new Date(rating.dateTime).toLocaleDateString()}</span>
                            </div>
                        </div>)}
                    </div>

                    :
                    <div className='column center height40 r-norating-found'>
                        <i class="fa-solid fa-star secondary-text"></i>
                        <h4 className='text-center'>No User Ratings Found: Please Rate Your Adjuster</h4>
                        <span className='text-center'>
                            We noticed you haven't rated any adjusters yet. Please find your adjuster and provide your valuable ratings and reviews to help us improve our services.</span>
                    </div>
                :
                <div className='column center height60'>
                    <APILoader />
                </div>}
        </div>
    );
};

function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Ratings);