import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { authenticationService } from '../../../_services/authentication.service';
import { onChangeHandler } from '../../../_helpers/imageUploadValidator';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import { APILoader } from '../../../components/APILoader';
import FieldsGenerator from '../../../components/FieldsGenerator';
import { authenticationActions } from '../../../_actions/authentication.actions';
import fillInObject from '../../../_helpers/fillInObject';
import generateValidation from '../../../_helpers/generateValidation';
import getImageURL from '../../../_helpers/getImageURL';
import { compressString } from '../../../_helpers/compressString';

const Profile = ({ dispatch, infosetting, user }) => {
    const [selectedProfileImage, setProfileImage] = useState(null);
    const [bluePrint, setBluePrint] = useState({
        'profileImage': {
            type: String,
            label: "Profile Image",
            placeholder: "Profile Image",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9]{4,15}",
            hidden: true
        },
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true,
            disabled: true
        },
        'name': {
            type: String,
            label: "Name",
            placeholder: "Enter Name",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9 ]{4,25}"
        },
        'state': {
            ref: 'states',
            label: "State",
            placeholder: "Please Select State",
            dataType: "ParentDropDown",
            displayOnHeader: true,
            default: null,
            entityAPI: "states",
            entity: "states",
            displayfield: "name",
            required: true,
        },

        // 'phoneNo': {
        //     type: String,
        //     label: "Phone Number",
        //     placeholder: "Enter Phone Number",
        //     icon: "fas fa-phone",
        //     iconColor: "color-primary",
        //     dataType: "String",
        //     default: "",
        //     required: true,
        //     minLength: 7
        // },

    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches ? true : false;

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);

    useEffect(() => {
        if (infosetting) {
            document.title = `Profile | ${infosetting.title}`;
        }
    }, [infosetting]);


    let loaded = false;
    useEffect(() => {
        if (user && !loaded) {
            loaded = true;
            setFormObj(user);
        }
    }, [user]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }

    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const onImageChangeHandler = event => {
        onChangeHandler(event, toast).then(({ loadedImage, renderImage }) => {
            //render image
            handleChageCustom("profileImage", renderImage);
            //set for uploading to server
            setProfileImage(loadedImage);
        });
    }

    const handleSubmit = (e) => {
        e && e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        setLoading(true);
        let form = new FormData();
        if (selectedProfileImage) {
            form.append('profileImage', selectedProfileImage)
        }
        form.append('name', formObj.name);
        form.append('email', formObj.email);
        form.append('state', formObj.state);

        authenticationService.update(form).then(res => {
            setLoading(false);
            toast.success("Profile Updated");
            dispatch(authenticationActions.getCurrentUser())

        }).catch(err => {
            setLoading(false);
            toast.error(err.response.data.message);
        })
    }

    return (
        user && formObj ? <div>
            <h3>Profile</h3>
            <p className='form-sub-heading'>Manage your {infosetting.title} Profile</p>
            <hr className='hr2' />

            <div className="row mt-20">
                <img src={getImageURL(formObj.profileImage)} className="profile-img" />
                <div className="ml-10">
                    <span className="secondary-text name-text">{user.name}</span>
                    <br />
                    <span className="primary-text mt-5">{isMobile ? compressString(user.email, 20) : user.email}</span>
                    <br />
                    <input type="file" className="custom-file-input-p mt-10" name="file" onChange={onImageChangeHandler} />
                </div>
            </div>
            <form className="form-container mt-20" onSubmit={handleSubmit}>
                <FieldsGenerator
                    errors={errors}
                    formObj={formObj}
                    handleChageCustom={handleChageCustom}
                    handleChange={handleChange}
                    isLoading={isLoading}
                    bluePrint={bluePrint}
                    fieldClass="col-sm-12"
                    key={0}
                />

                <button
                    type="submit"
                    className="btn btn-secondary btn-block mt-20"
                    onClick={() => handleSubmit()}
                    disabled={isLoading}
                >
                    {isLoading ? "Please Wait..." : "Save"}
                </button>
            </form>
        </div>
            :
            <div className='height60 row center'>
                <APILoader />
            </div>
    );
};

function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Profile);