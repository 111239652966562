import React from 'react';
import './style.css';
import { Link, Outlet, useLocation } from 'react-router-dom';
import _nav from './_nav';

const AuthenticatedLayout = () => {
    const location = useLocation()
    return (
        <div className='auth-container height100'>
            <div className='auth-sidebar'>
                <div className='auth-sidebar-container'>
                    {_nav.map((navItem, key) => <Link
                        className={`auth-sidebar-item ${navItem.to === location.pathname ? 'active' : ''}`}
                        to={navItem.to} key={key}>

                        <i class={navItem.icon}></i>
                        <span>{navItem.title}</span>

                    </Link>)}
                </div>
            </div>

            <div className='auth-content'>
                <div className='crud-container'>
                    <Outlet />
                </div>

            </div>
        </div>
    );
};

export default AuthenticatedLayout;