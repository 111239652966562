import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import generateValidation from '../../../_helpers/generateValidation';
import { authenticationService } from '../../../_services/authentication.service';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import fillInObject from '../../../_helpers/fillInObject';
import './style.css'
import FieldsGenerator from '../../../components/FieldsGenerator';
import { Alert } from '@mui/material';
import SocialButtons from '../../../components/SocialButtons';

const Signup = ({ infosetting, dispatch }) => {
    const navigate = useNavigate();
    const bluePrint = {
        'name': {
            type: String,
            label: "Name",
            placeholder: "Enter Name",
            dataType: "String",
            displayOnHeader: true,
            default: "",
            // pattern: "^(?!.*\\.\\.)(?!.*\\.$)[^\\W][\\w.]{0,29}$",
            required: true
        },
        'state': {
            ref: 'states',
            label: "State",
            placeholder: "Please Select State",
            dataType: "ParentDropDown",
            displayOnHeader: true,
            default: null,
            entityAPI: "states",
            entity: "states",
            displayfield: "name",
            required: true,
        },
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        },
        'password': {
            type: String,
            label: "Password",
            placeholder: "Enter Password",
            icon: "ti-lock",
            iconColor: "color-primary",
            dataType: "PasswordHash",
            default: "",
            required: true,
        },
    };
    const [formObj, setFormObj] = useState(null);
    const [formError, setFormError] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [checkTerm, setCheckTerm] = useState(false);

    useEffect(() => {
        if (infosetting) {
            document.title = `Signup | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);


    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];

        setLoading(true);
        authenticationService.register(formObj)
            .then(m => {
                toast.success("You are successfully registered. Now you can login with your credentials.");
                navigate(`/auth/email/register-success/${m.email}`);
            })
            .catch(err => {
                toast.error(err.response.data.message);
                setLoading(false);
            })
    }
    return (
        <div className='height100 pd-top'>
            <div className='column center'>
                <h2>
                    Signup
                </h2>

                <form onSubmit={handleSubmit} className='form-container column center'>
                    {formObj &&
                        <FieldsGenerator
                            errors={errors}
                            formObj={formObj}
                            handleChageCustom={handleChageCustom}
                            handleChange={handleChange}
                            loggingIn={false}
                            isLoading={isLoading}
                            bluePrint={bluePrint}
                            key={0}
                        />
                    }
                    <div className='row center mt-10'>
                        <p className='form-agreement'>By clicking 'Continue' you agree to the Terms of Use and our Privacy Policy. {infosetting.title} is designed for and targeted to U.S. audiences and is governed by and operated in accordance with U.S. laws.</p>
                    </div>
                    {alert.message && <Alert severity={alert.type} className="mt-3">{alert.message} </Alert>}

                    <button type="submit" class="btn btn-block btn-sm mt-5" disabled={isLoading}>
                        {isLoading ? "Please Wait..." : "Register Now"}
                    </button>

                    {/* <SocialButtons navigate={navigate} setLoading={setLoading} dispatch={dispatch} /> */}

                </form>
            </div>
        </div>
    );
};



function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Signup);