import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';
import { APILoader } from '../../../components/APILoader';
import getColor from '../../../_helpers/getColor';
import { toast } from 'react-toastify';

const SearchBar = ({ onSubmit, defaultValue }) => {
    const [searchQueryTemp, setSearchQueryTemp] = useState("");
    useEffect(() => {
        if (searchQueryTemp === "" && defaultValue !== "") {
            setSearchQueryTemp(defaultValue);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(searchQueryTemp)
    }
    return <form className='hsb-container search-bar' onSubmit={handleSubmit}>
        <i class="fa-solid fa-user-tag"></i>
        <input name='search' placeholder='Adjuster or Company Name' value={searchQueryTemp} onChange={({ target }) => setSearchQueryTemp(target.value)} />
    </form>
}

const Search = ({ infosetting, user, loggedIn }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [adjusters, setAdjusters] = useState(null);
    const [page, setPage] = useState(1);
    const [pageEnded, setPageEnded] = useState(false);

    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (infosetting) {
            document.title = `Search Adjuster | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        let q = searchParams.get("q");
        let state = searchParams.get("state");
        let company = searchParams.get("company");
        if (q === "" && !(company || state)) {
            return navigate("/");
        }
        

        fetchStates();
        if (state && company) {
            setSelectedState(state);
        }

        setSearchQuery(q);
        doSearch(state && company ? { state, company } : q);
    }, [searchParams]);


    const doSearch = (q, mPage = 1) => {
        if (mPage == 1) {
            setAdjusters(null);
            setPageEnded(false);
        }

        setPage(mPage);
        let promise = null;
        if (q.state && q.company) {
            promise = apiService.filter("adjusters", { query: q, sortQuery: { first_name: 1 } }, { page: mPage })
        } else {
            promise = apiService.searchWithPagination("adjusters", mPage, q, 'first_name', 'asc')

        }

        promise.then(res => {
            setPageEnded(res.data.length < res.per_page);
            if (mPage === 1) {
                setAdjusters(res);
                return;
            }

            let nAdjusters = JSON.parse(JSON.stringify(adjusters));
            nAdjusters.data = nAdjusters.data.concat(res.data);
            nAdjusters["current_page"] = res.current_page;
            nAdjusters["total"] = nAdjusters.total + res.total;
            setAdjusters(nAdjusters);
        });

    }

    useEffect(() => {
        if (selectedState) {
            setCompanies([]);

            let sCompany = searchParams.get("company");

            if (company) {
                setCompany(null);
            }

            if (!company && sCompany) {
                setCompany(sCompany)
            }
            apiService.filter('companies', { query: { state: selectedState } })
                .then(setCompanies)
        }
    }, [selectedState]);

    const fetchStates = () => {
        apiService.getAll('states')
            .then(setStates)
    }

    const onSearchSubmit = (value) => {
        navigate(`/search?q=${encodeURI(value)}`);
    }

    const onSearchSelectSubmit = (e) => {
        e.preventDefault();

        if (selectedState && company) {
            return navigate(`/search?state=${selectedState}&company=${company}`)
        }

        if (searchQuery === "") {
            return toast.error("Please enter adjuster name!");
        }

        navigate(`/search?q=${encodeURI(searchQuery)}`)
    }

    const SearchForm = () => {
        return <form className='row hsb-row-container' onSubmit={onSearchSelectSubmit}>
            <SearchBar onSubmit={onSearchSubmit} defaultValue={searchQuery} />

            <div className='hsb-container mt-20'>
                <i class="fa-solid fa-globe"></i>
                <select name='state' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                    <option>Select State</option>
                    {states.map((s, i) => <option value={s._id}>{s.name}</option>)}
                </select>
            </div>

            <div className='hsb-container mt-20'>
                <i class="fa-solid fa-building"></i>
                <select name='state' value={company} onChange={(e) => setCompany(e.target.value)}>
                    <option>Select Company</option>
                    {companies.map((s, i) => <option key={i} value={s._id}>{s.name}</option>)}

                </select>
            </div>
            <button className='btn btn-primary mt-20'>
                Search
            </button>
        </form>
    }

    return (
        <div className='height100 padder'>
            {adjusters ?
                adjusters.total > 0 ?
                    // Adjuster Found
                    <Fragment>

                        <SearchForm />
                        <div className='search-container'>
                            <h3 className='a-search-result'>
                                {adjusters.total} adjuster{adjusters.total > 1 ? 's' : ''} {searchQuery && searchQuery.length > 0 && `with "${searchQuery}"`}
                                <br />
                                <br />
                                <div className='red-text'>
                                    Can't find the adjuster you are looking for? <Link className='secondary-text' to="/account/adjuster/manage">Click here to create one</Link>
                                </div>
                            </h3>
                            <div className='adjusters-container'>
                                {adjusters.data.map((adjuster, index) => <Link className='adjuster-item row' key={index} to={`/adjuster/${adjuster._id}`}>
                                    <div className='a-rating-container column'>
                                        <span className='a-rating-heading'>Rating</span>
                                        <div className='a-rating-box row center' style={{ backgroundColor: getColor(adjuster.rating) }}>
                                            {adjuster.rating === 0 ?
                                                <span className='a-rating'>
                                                    NR
                                                </span>
                                                :
                                                <span className='a-rating'>
                                                    {adjuster.rating}{adjuster.rating.toString().length === 1 ? '.0' : ''}
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className='a-name-container column'>
                                        <span className='a-name'>{adjuster.first_name} {adjuster.last_name} </span>
                                        <span className='a-company-name'>{adjuster.companyObj.name}</span>
                                    </div>

                                    <div className='a-state-container row'>
                                        <span className='a-state-name'>{adjuster.stateObj.name}, US</span>
                                    </div>
                                </Link>)}

                                {!pageEnded && <div className='column center mt-20'>
                                    <button className='btn btn-primary mt-10' onClick={() => doSearch(searchQuery, page + 1)}>
                                        Show More
                                    </button>
                                </div>}

                            </div>
                        </div>
                    </Fragment>
                    :
                    //Adjuster not found
                    <Fragment>
                        <SearchForm />
                        <h3 className='mt-20'>No adjuster {searchQuery && searchQuery.length > 0 ? `with "${searchQuery}" in its name` : `Found`}.</h3>
                        <p>Use the search bar above and check the spelling or try an alternate spelling.</p>
                    </Fragment>
                :
                //Loader
                <div className='height60 row center'>
                    <APILoader />
                </div>
            }


        </div >
    );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Search);