import React from 'react';
import { Link } from 'react-router-dom';

const JoinRMPFamily = ({ infosetting, redirect }) => {
    return (
        <div className='column center padder hj-container'>
            <span className='home-banner-title'>Join the {infosetting.shortTitle} Family</span>
            <span className='hj-caption'>Love {infosetting.shortTitle}? Let's make it official.</span>


            <div className='hj-content'>
                <div className='hj-item'>
                    <i class="fa-solid fa-user-pen"></i>
                    <span>Manage and edit your <br />ratings</span>
                </div>
                <div className='hj-item'>
                    <i class="fa-solid fa-lock"></i>
                    <span>Your ratings are <br />always anonymous</span>
                </div>
                <div className='hj-item'>
                    <i class="fa-solid fa-thumbs-up"></i>
                    <span>Like or dislike ratings</span>
                </div>
            </div>

            <Link className='btn hj-btn' to={`/auth/signup${redirect ? '?redirect=' + encodeURI(redirect) : ''}`}>
                Sign up now!
            </Link>

        </div>
    );
};

export default JoinRMPFamily;