import Axios from 'axios';
import React, { Component } from 'react';
import config from '../../_config';
import LocationSearchInput from '../LocationSearchInput';
import './AddressLocationField.css'
import './style.css';

class AddressLocationFieldJS extends Component {
    state = {
        marking: false,
        marked: false,
        mapId: "",
        mapRef: null,
        polygonRef: null
    }
    componentDidMount() {
        this.initMap()
    }

    initMap = () => {

        setTimeout(() => {
            const { name, placeholder, value, location, geoFenceBluePrint, geoFence, disabled, onChange, viewable } = this.props;
            const mapId = `map-${name}`;
            this.setState({ mapId })
            let myLatLng = this.myLatLng();
            const map = new window.google.maps.Map(
                document.getElementById(mapId),
                {
                    zoom: config.mapZoom,
                    center: this.myLatLng(),
                    streetViewControl: false,
                    fullscreenControl: false
                }
            );

            this.setPolygonPath(this.props.geoFence, map);

            this.setState({ mapRef: map })


            if (!viewable) {
                map.addListener("dragend", () => {
                    // marker.setPosition({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
                    this.geoCoding(name, null, map.getCenter()).then(g => {
                        this.props.onChange && this.props.onChange(g);
                    })
                });

                map.addListener("click", ({ latLng }) => {
                    const { marking, marked } = this.state;
                    if (geoFenceBluePrint && marking) {
                        this.handleRegionPointSelect(latLng, map)
                    }
                });


                map.addListener("zoom_changed", () => {
                    this.geoCoding(name, null, map.getCenter()).then(g => {
                        this.props.onChange && this.props.onChange(g);
                    })
                })
            }

        }, 500);



    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location && prevProps.location !== this.props.location && this.state.mapRef) {
            this.state.mapRef.panTo(new window.google.maps.LatLng(this.props.location.coordinates[0], this.props.location.coordinates[1]));
        }
    }

    setPolygonPath = (geoFence, map) => {
        if (geoFence && geoFence.coordinates) {
            const { polygonRef } = this.state;
            function getPolygonPaths(crds) {
                let tPath = [];
                crds.forEach(c => {
                    tPath.push({ lat: c[0], lng: c[1] })
                });
                return tPath;
            }

            const polygonConfig = new window.google.maps.Polygon({
                paths: getPolygonPaths(geoFence.coordinates[0]),
                strokeColor: "#0CA8DB",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#0CA8DB",
                fillOpacity: 0.35,
            });
            if (polygonRef) {
                polygonRef.setPaths(getPolygonPaths(geoFence.coordinates[0]));
                this.setState({ polygonRef: polygonRef })
            } else {
                polygonConfig.setMap(map);
                this.setState({ polygonRef: polygonConfig })
            }
        }

    }

    handleRegionPointSelect = (latLng, map) => {
        let { geoFence, value, location, name } = JSON.parse(JSON.stringify(this.props));
        if (!geoFence) {
            geoFence = { type: "Polygon", coordinates: [[]] }
        }
        geoFence.coordinates[0].push([latLng.lat(), latLng.lng()]);


        this.setPolygonPath(geoFence, map)
        this.props.onChange({
            [name + "GeoFence"]: geoFence
        }, true)

    }

    geoCoding = (name, address, latLng = null) => {
        let params = latLng ? { key: config.googleMapAPIKey, latlng: latLng.lat() + "," + latLng.lng() } : { key: config.googleMapAPIKey, address }
        return Axios.get("https://maps.googleapis.com/maps/api/geocode/json", { params }).then(response => {
            let result = response.data.results[0];
            if (result) {
                return {
                    [name]: result.formatted_address,
                    [name + "Location"]: { type: "Point", coordinates: latLng ? [latLng.lat(), latLng.lng()] : [result.geometry.location.lat, result.geometry.location.lng] }
                }
            } else {
                throw Object.assign({
                    message: "No Result Found"
                })
            }
        })
    }

    myLatLng = () => {
        const { location } = this.props;
        return location && location.coordinates ? { lat: location.coordinates[0], lng: location.coordinates[1] } : { lat: config.defaultLatLng.lat, lng: config.defaultLatLng.lng };
    }


    handleClear = () => {
        let { name } = this.props;
        this.setState({ marked: false, marking: false })
        this.props.onChange({
            [name + "GeoFence"]: { type: "Polygon", coordinates: [[]] }
        }, true)
        this.setPolygonPath({ coordinates: [[]] })
    }

    handleMarkRegion = () => {
        this.setState({ marking: true })
    }

    render() {
        const { name, placeholder, value, location, geoFenceBluePrint, geoFence, disabled, onChange, viewable } = this.props;
        const { marking, marked, mapId } = this.state;
        return (
            <React.Fragment>
                {
                    !viewable && <LocationSearchInput
                        searchAddress={{ address: value, latLng: this.myLatLng() }}
                        label={placeholder}
                        onChange={(g) => {
                            this.props.onChange({ [name]: g.address, [name + "Location"]: { type: "Point", coordinates: [g.latLng.lat, g.latLng.lng] } })
                        }}
                    />
                }

                <img className="marker-image" src="/assets/images/icons8-marker.png"></img>

                <React.Fragment>
                    <div id={mapId} style={{ height: `300px`, width: `100%`, marginBottom: `30px`, zIndex: 1 }}></div>
                </React.Fragment>

                {
                    geoFenceBluePrint && !viewable && <div className="gfRow" >
                        <div className="gfItem" onClick={this.handleClear}>
                            <i class="fas fa-brush"></i>
                            <span>Clear</span>
                        </div>

                        <div className={`gfItem ${marking ? 'gfActive' : ''}`}
                            onClick={() => !marking && this.handleMarkRegion()} >
                            <i i class="fas fa-map-marked"></i>
                            <span>Mark Region</span>
                        </div>
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default AddressLocationFieldJS;
